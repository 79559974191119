<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
        <!-- <template>
          {{ loanApprover() }}
        </template> -->

          <template v-if="$permissionAbility(LOAN_CREATE, permissions) && applyPermission">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Apply
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Loan amount -->
          <template v-if="props?.column?.field === 'format_loan_amount'">
            <div>
              <FormatCurrency :currency="defaultCurrency" :amount="props?.row?.principal_amount" />
            </div>
          </template>

          <template v-if="props?.column?.field === 'format_currency'">
            <div>
              <FormatCurrency :type="'currency'" :currency="defaultCurrency" :amount="props?.row?.principal_amount" />
            </div>
          </template>

          <!-- installment_end_date -->
          <template
            v-if="props?.column?.field === 'format_installment_end_date'"
          >
            <div>
              <span
                >{{ formattedDate(props?.row?.installment_end_date) }}
              </span>
            </div>
          </template>

          <template
              v-if="props?.column?.field === 'format_borrowed_by'"
          >
            <UserCard :user="props?.row?.borrower" />
          </template>

          <!-- remaining_amount -->
          <!-- <template v-if="props?.column?.field === 'format_remaining_amount'">
            <div>
              <span>{{ props?.row?.remaining_amount }} </span>
            </div>
          </template> -->

          <!-- Status -->
          <template v-if="props?.column?.field === 'format_status'">
            <div>
              <b-badge v-if="props?.row?.status === 'applied'" pill variant="light-primary">
                Applied
              </b-badge>

              <b-badge v-if="props?.row?.status === 're_submit'" pill variant="light-primary">
                Re Submit
              </b-badge>

              <b-badge v-if="props?.row?.status === 'sent_back'" pill variant="light-warning">
                Sent Back
              </b-badge>

              <b-badge v-if="props?.row?.status === 'final_apply'" pill variant="light-warning">
                Final Apply
              </b-badge>

              <b-badge v-if="props?.row?.status === 'cancel'" pill variant="light-danger">
                Cancel
              </b-badge>

              <b-badge v-if="props?.row?.status === 'rejected'" pill variant="light-danger">
                Rejected
              </b-badge>

              <b-badge v-if="props?.row?.status === 'approved'" pill variant="light-success">
                Approved
              </b-badge>
            </div>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <template v-if="$permissionAbility(LOAN_SHOW, permissions)">
                  <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template>

                <!-- <template>
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template>
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template> -->
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-loan-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Loan ' : 'Apply Loan'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
      size="lg"
    >
      <validation-observer ref="salaryPolicyValidation">
        <b-form v-on:submit.prevent="validationForm">
          <b-row>
            <b-col class="mt-1" xs="12" lg="12" md="12">
              <!-- principal amount -->
              <b-form-group
                label="Principal Amount"
                label-for="principal_amount"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="principal amount"
                  vid="principal_amount"
                  rules="required"
                >
                  <b-form-input
                    id="principal_amount"
                    type="number"
                    v-model="principalAmount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Principal Amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" xs="12" lg="12" md="12">
              <!-- Purpose -->
              <b-form-group
                label="Purpose"
                label-for="purpose"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Purpose"
                  vid="purpose"
                  rules="required"
                >
                  <b-form-textarea
                    id="purpose"
                    type="text"
                    v-model="purposeText"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Purpose"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" xs="6" lg="6" md="6">
              <!-- installment_start_date -->
              <b-form-group
                label="Installment Start Date"
                label-for="installment_start_date"
                class="required-label"
              >
                <ValidationProvider
                  name="installment start date"
                  v-slot="{ errors }"
                  vid="installment_start_date"
                  rules="required"
                >
                  <date-picker
                    v-model="startDate"
                    type="month"
                    placeholder="YYYY-MM"
                    :editable="false"
                    :disabled-date="disabledDate"
                    @change="changeStartDate"
                  ></date-picker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" xs="6" lg="6" md="6">
              <!-- installment_end_date -->
              <b-form-group
                label="Installment End Date"
                label-for="installment_end_date"
                class="required-label"
              >
                <ValidationProvider
                  name="installment end date"
                  v-slot="{ errors }"
                  vid="installment_end_date"
                  rules="required"
                >
                  <date-picker
                    v-model="endDate"
                    type="month"
                    placeholder="YYYY-MM"
                    :editable="false"
                    :disabled-date="disabledDate"
                    @change="changeEndDate"
                  ></date-picker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isSalaryPolicyFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

  <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BRow,
  BCol,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import { LOAN_CREATE, LOAN_SHOW, LOAN_APPROVER_ACCESS, } from "@/helpers/permissionsConstant";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'
import UserCard from '@/layouts/components/UserCard.vue'
import moment from 'moment';

export default {
  name: "LoanView",
  components: {
    UserCard,
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormDatepicker,
    BFormTextarea,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      LOAN_CREATE,
      LOAN_SHOW,
      LOAN_APPROVER_ACCESS,
      modelType: "",
      name: "",
      defaultCurrency: "",

      // salary policy
      salaryPolicyId: "",
      salaryPolicyName: "",
      salaryPolicyBasic: "",
      salaryPolicyHouseRent: "",
      salaryPolicyConveyance: "",
      salaryPolicyMedical: "",
      salaryPolicyEntertainment: "",

      //loan
      principalAmount: "",
      installmentStartDate: "",
      installmentEndDate: "",
      purposeText: "",
      loanUser: "",
      startDate: "",
      endDate: "",

      applyPermission: false,

      pageLength: 10,
      columns: [
        {
          label: "User",
          field: "format_borrowed_by",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Loan Amount",
          field: "format_loan_amount",
          sortable: false,
        },
        {
          label: "Installment End Date",
          field: "format_installment_end_date",
          sortable: false,
        },
        // {
        //   label: "Remaining Amount",
        //   field: "format_remaining_amount",
        //   sortable: false,
        // },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDateTime,
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSalaryPolicyFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const [getSetting, getCurrencies] =
          await Promise.all([
            this.getSetting({
              select:
                  "currency_id",
            }),
            this.getCurrencies(),
          ]);

      // getSetting
      const currencyId = getSetting?.data?.data?.currency_id;

      // getCurrencies
      const currencyData = getCurrencies?.data?.data?.filter((item) => {
        return item?.id === currencyId
      });

      this.defaultCurrency = currencyData?.[0]?.currency;

      // Get apply permission according to joining date
      this.checkPermission();

      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    showModal() {
      this.$bvModal.show("modal-loan-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-loan-form");
      this.resetModal();
    },

    resetModal() {
      this.principalAmount = "";
      this.purposeText = "";
      this.installmentStartDate = "";
      this.installmentEndDate = "";
      this.startDate = "";
      this.endDate = "";
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-loan-details",
        params: { id },
      });
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    loanApproverView() {
      if (this.$permissionAbility(this.LOAN_APPROVER_ACCESS, this.permissions)) {
        this.loanUser = "";
      }else{
        this.loanUser = this.authUser.id;
      }
    },

    disabledDate(date) {
      const now = moment();
      const selectedDate = moment(date);
      return selectedDate.isBefore(now, 'month');
    },

    changeStartDate() {
      if (this.startDate) {
        const startDate = new Date(this.startDate);

        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");

        const formattedStartDate = `${year}-${month}-${day}`;

        this.installmentStartDate = formattedStartDate;
      } else {
        this.installmentStartDate = null;
      }
    },

    changeEndDate() {
      if (this.endDate) {
        const endDate = new Date(this.endDate);

        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");

        const formattedStartDate = `${year}-${month}-${day}`;

        this.installmentEndDate = formattedStartDate;
      } else {
        this.installmentEndDate = null;
      }
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    checkPermission() {
      if (this.authUser?.join_date) {
        const joinDate = new Date(this.authUser.join_date);
        const today = new Date();
        
        // Calculate difference in years
        const yearDifference = today.getFullYear() - joinDate.getFullYear();
        const isOneYearOrMore = 
          yearDifference > 1 || 
          (yearDifference === 1 && today.getMonth() >= joinDate.getMonth() && today.getDate() >= joinDate.getDate());

        // Set applyPermission to true if one year has passed
        this.applyPermission = isOneYearOrMore;
      }
    },

    async getLoanItems(params) {
      return await this.$api.get("api/loans", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          authUser: params.authUser,
        },
      });
    },

    async loadItems() {

      this.loanApproverView();

      try {
        const loanItems = await this.getLoanItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
          authUser: this.loanUser,
        });

        const data = loanItems?.data?.data;

        const meta = loanItems?.data?.meta;
        this.totalRecords = meta?.pagination?.total;

        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    formattedDate(value) {
      const date = new Date(value);

      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${month} ${year}`;
    },

    validationForm: async function () {
      this.$refs.salaryPolicyValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.salaryPolicyValidation.reset();
            if (this.modelType == "editModel") {
              this.isSalaryPolicyFormSubmitLoading = true;
              await this.$api.put(
                `/api/salary-policies/${this.salaryPolicyId}`,
                {
                  title: this.salaryPolicyName,
                  basic: this.salaryPolicyBasic,
                  house_rent: this.salaryPolicyHouseRent,
                  conveyance: this.salaryPolicyConveyance,
                  medical: this.salaryPolicyMedical,
                  entertainment: this.salaryPolicyEntertainment,
                }
              );
              this.isSalaryPolicyFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Loan Applied Successfully",
                },
              });
            } else {
              this.isSalaryPolicyFormSubmitLoading = true;
              await this.$api.post("/api/loans", {
                principal_amount: this.principalAmount,
                installment_start_date: this.installmentStartDate,
                installment_end_date: this.installmentEndDate,
                purpose: this.purposeText,
                loan_applied_user_id: this.authUser.id,
              });
              this.isSalaryPolicyFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Loan Successfully Applied",
                },
              });
            }
          } catch (error) {
            this.isSalaryPolicyFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.salaryPolicyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}

.mx-datepicker {
  width: 100%;
}
</style>

